<template>
  <ul class="list list--iconed" :class="`list--${list.theme}`">
    <li>
      <i>
        <img :src="`/img/icons/ico-${list.key}-${list.theme}.svg`" :alt="list.key" />
      </i>
      <div>
        <ui-text tag="3" class="tw-600 mb-2 mb-md-3">
          {{ list.title }}
        </ui-text>
        <ui-text tag="1" class="tc-gray lh-200 lh-md-35">
          {{ list.text }}
        </ui-text>
      </div>
    </li>
  </ul>
</template>
<script>
import uiText from '@/components/UI/Text'
export default {
  components: {
    uiText
  },
  computed: {
    list () {
      return this.$parent.list
    }
  }
}
</script>
