<template>
  <ul class="list">
    <li v-for="(item, i) in list" :key="`list-item-${i}`">
      {{ item.text }}
    </li>
  </ul>
</template>
<script>
export default {
  computed: {
    list () {
      return this.$parent.list
    },
    type () {
      return this.$parent.type
    },
    theme () {
      return this.$parent.theme
    },
    space () {
      return this.$parent.spaceClass
    }
  }
}
</script>
