<template>
  <ul class="list list--checked" :class="`list--${theme}`">
    <li v-for="(item, i) in list" :key="`checked-list-item-${i}`" :class="{'mb-3': i !== list.length - 1}">
      <i>
        <img :src="`/img/icons/ico-checked-${theme}.svg`" alt="checked" />
      </i>
      <ui-text class="tc-gray ts-14 ts-sm-16 ts-md-20 lh-150 lh-sm-35">
        {{ item }}
      </ui-text>
    </li>
  </ul>
</template>
<script>
import uiText from '@/components/UI/Text'
export default {
  components: {
    uiText
  },
  computed: {
    list () {
      return this.$parent.list
    },
    theme () {
      return this.$parent.theme
    }
  }
}
</script>
