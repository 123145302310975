<template>
  <ul class="list list--numbered" :class="`list--${theme}`">
    <li v-for="(item, i) in list" :key="`list-item-${i}`" :class="space">
      <i>{{ i + 1 }}</i>
      <template v-if="Array.isArray(item.text)">
        <span class="ts-14 ts-md-16 lh-200">
          <span v-for="(text, t) in item.text" :key="`list-item-text-${t}`"
                :class="highlightClass(t)">
          {{ text }}
        </span>
        </span>
      </template>
      <template v-else>
        <span class="tc-gray ts-14 ts-md-16 tw-400 lh-200">
          {{ item.text }}
        </span>
      </template>
    </li>
  </ul>
</template>
<script>
export default {
  computed: {
    list () {
      return this.$parent.list
    },
    type () {
      return this.$parent.type
    },
    theme () {
      return this.$parent.theme
    },
    space () {
      return this.$parent.spaceClass
    }
  },
  methods: {
    highlightClass (i) {
      return i % 2 ? `tc-${this.theme} tw-500` : 'tc-gray tw-400'
    }
  }
}
</script>
