<template>
  <div class="partnership">
    <slot></slot>
    <div class="ico--partnership mb-3 mb-md-4" :class="`ico--${item.theme}`">
      <img :src="`/img/icons/ico-${item.key}-${item.theme}.svg`" :alt="item.key" />
    </div>
    <ui-text tag="6" class="tw-600 mb-3">
      {{ item.title }}
    </ui-text>
    <ui-text tag="2" class="tc-gray lh-175">
      {{ item.text }}
    </ui-text>
  </div>
</template>
<script>
import uiText from '@/components/UI/Text'
export default {
  components: {
    uiText
  },
  props: {
    item: {
      required: true
    }
  }
}
</script>
