<template>
  <component v-bind:is="type"></component>
</template>
<script>
import Default from '@/components/UI/Lists/Default'
import Numbered from '@/components/UI/Lists/Numbered'
import Iconed from '@/components/UI/Lists/Iconed'
import Checked from '@/components/UI/Lists/Checked'
export default {
  components: {
    Default, Numbered, Iconed, Checked
  },
  props: {
    list: {
      required: true
    },
    type: {
      required: false,
      type: String,
      default: 'default'
    },
    theme: {
      required: false,
      type: String,
      default: 'yellow'
    },
    spaceClass: {
      required: false,
      type: String,
      default: 'mb-2'
    }
  }
}
</script>
