<template>
  <main class="main" data-view="partnership">
    <section class="section" id="s1">
      <comp-card img="2">
        <ui-text tag="3" class="tc-waikawa tw-500 lh-35 mb-4">
          {{ cc.s1.pre }}
        </ui-text>
        <ui-title tag="4" class="lh-125 mb-4">
          {{ cc.s1.title }}
        </ui-title>
        <ui-text tag="3" class="tc-gray lh-35 mb-4">
          {{ cc.s1.text }}
        </ui-text>
        <ui-list :list="cc.s1.list.items" type="checked" theme="green" />
      </comp-card>
    </section>
    <section class="section" id="s2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ui-text tag="3" class="tc-waikawa tw-500 lh-35 ta-center mb-4">
              {{ cc.s2.pre }}
            </ui-text>
            <router-link tag="a" :to="`/${$i18n.locale}/bidaskbit`" class="img img--partnership">
              <img src="/img/pages/partnership/img-s2-1.svg" alt="bidaskbit" />
              <img src="/img/pages/partnership/img-s2-2.svg" alt="bidaskbit" />
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--snow" id="s3">
      <div class="container">
        <div class="row flex-column-reverse flex-xl-row">
          <div class="col-12 col-lg-8 offset-lg-2 col-xl-7 offset-xl-0 pe-xl-5">
            <div class="row">
              <div class="col-12 col-md-6 mb-4" v-for="(item, i) in cc.s3.blocks" :key="`block-partnership-${i}`">
                <template v-if="i === 0">
                  <comp-partnership :item="item">
                    <span class="sticker sticker--green sticker--partnership"> #penízenikdynespí </span>
                  </comp-partnership>
                </template>
                <template v-else>
                  <comp-partnership :item="item" />
                </template>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8 offset-lg-2 col-xl-5 offset-xl-0 mb-5 mb-xl-0">
            <div class="wrapper d-flex flex-column justify-content-center">
              <ui-title tag="2" class="lh-125 ta-center ta-md-start mb-3 mb-md-4">
                {{ cc.s3.title }}
              </ui-title>
              <ui-text tag="3" class="tc-yellow lh-175 ta-center ta-md-start mb-3 mb-md-4">
                {{ cc.s3.text[0] }}
              </ui-text>
              <ui-text tag="2" class="tc-gray lh-175 ta-center ta-md-start mb-3 mb-md-4">
                {{ cc.s3.text[1] }}
              </ui-text>
              <ui-btn :data="cc.s3.btn[0]" type="1" class="btn-w-160 btn-w-sm-200 btn-s1 btn-sm-s2 mb-4 mx-auto mx-md-0"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="s4">
      <comp-banner-app />
    </section>
  </main>
</template>
<script>
import compBannerApp from '@/components/Banners/BannerApp'
import compCard from '@/components/Card'
import compPartnership from '@/components/Partnership'
import uiTitle from '@/components/UI/Title'
import uiText from '@/components/UI/Text'
import uiList from '@/components/UI/List'
import uiBtn from '@/components/UI/Btn'
// import uiLink from '@/components/UI/Link'
export default {
  components: {
    compBannerApp, compCard, compPartnership, uiTitle, uiText, uiList, uiBtn
  },
  computed: {
    cc () {
      return this.$t('pages.partnership')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.partnership')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        // { name: 'description', content: meta.description }
      ]
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
